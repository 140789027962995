import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import _, { isMatchWith } from "lodash";
import { Formik } from "formik";
import {
    makeStyles,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    TablePagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Grid,
    Chip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
    EnhancedTableToolbar,
    EnhancedTableHead,
    stableSort,
    getSorting
} from "../../../../components/EnhancedTable";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import LoadingButton from "../../../../components/LoadingButton";
import generateString from "../../../../components/utils/GenerateRandomChars";

import * as eventsStore from "../../../../store/ducks/events.duck";
import * as customersStore from "../../../../store/ducks/customers.duck";

const headRows = [
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    {
        id: "Password",
        numeric: false,
        disablePadding: false,
        label: "Password"
    },
    {
        id: "expire_datetime",
        numeric: false,
        disablePadding: false,
        label: "Status"
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    containerWrapper: {
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            // eslint-disable-line no-useless-computed-key
            width: "70%"
        }
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto",
        marginTop: "1rem",
        width: "100%"
    },
    button: {
        margin: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    }
}));

function EventShareDlg(props) {
    const {
        eventId,
        eventName,
        eventType,
        liteParticipantsList,
        getLiteParticipantsList,
        open,
        onClose,
        addLiteParticipant,
        removeLiteParticipant,
        customerId,
        customerValue,
        dispatch,
        intl
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openDelDlg, setOpenDelDlg] = React.useState(false);
    const [clickedValue, setClickedValue] = React.useState(-1);

    const [loading, setLoading] = React.useState(false);

    const [customer, setCustomer] = React.useState({ name: "", password: "" });
    const [meeting_customer, setMeeting_Customer] = React.useState({
        meeting_name: "",
        meeting_password: ""
    });
    const [extra_url, setExtraUrl] = React.useState("");
    const [extra_meeting_url, setExtraMeetingUrl] = React.useState("");
    const [isCopied, setCopied] = React.useState(false);

    useEffect(() => {
        if (!_.isNil(eventId) && eventId !== -1) {
            if (eventType == 2 && isMeeting == true) {
                getLiteParticipantsList(eventId, 2);
            } else {
                getLiteParticipantsList(eventId, 1);
            }
        }
    }, [eventId, getLiteParticipantsList]);

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            liteParticipantsList ? liteParticipantsList.length : 0 - page * rowsPerPage
        );

    const isSelected = id => selected.indexOf(id) !== -1;

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = liteParticipantsList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    const handleClose = () => {
        resetForm();
        resetMeetingForm();
        onClose();
    };

    function handleCopyUrl(event) {
        navigator.clipboard.writeText(extra_url);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }
    function handleCopyMeetingUrl(event) {
        navigator.clipboard.writeText(extra_meeting_url);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    function handleCloseDel(value) {
        resetForm();
        resetMeetingForm();
        setOpenDelDlg(false);
    }

    function handleDelete(event) {
        if (clickedValue !== -1) {
            removeLiteParticipant(clickedValue);
            setOpenDelDlg(false);
        }
        setClickedValue(-1);
    }

    function handleDeleteClick(event, id) {
        setClickedValue(id);
        setOpenDelDlg(true);
    }

    // Handle Change Customer Values
    const handleCustomerNameChange = (newValue, updateExtra = true) => {
        setCustomer(prevCustomer => {
            return { ...prevCustomer, name: newValue };
        });

        if (updateExtra) {
            setExtraUrl(
                window.location.origin +
                    `/event/${eventName}` +
                    `?name=${newValue}&password=${customer.password}`
            );
        }
    };
    const handleCustomerMeetingNameChange = (newValue, updateExtra = true) => {
        setMeeting_Customer(prevCustomer => {
            return { ...prevCustomer, meeting_name: newValue };
        });

        if (updateExtra) {
            setExtraMeetingUrl(
                window.location.origin +
                    `/event/${eventName}` +
                    `?name=${newValue}&password=${meeting_customer.meeting_password}`
            );
        }
    };

    const handleCustomerPasswordChange = (newValue, updateExtra = true) => {
        setCustomer(prevCustomer => {
            return { ...prevCustomer, password: newValue };
        });

        if (updateExtra) {
            setExtraUrl(
                window.location.origin +
                    `/event/${eventName}` +
                    `?name=${customer.name}&password=${newValue}`
            );
        }
    };
    const handleCustomerMeetingPasswordChange = (newValue, updateExtra = true) => {
        setMeeting_Customer(prevCustomer => {
            return { ...prevCustomer, meeting_password: newValue };
        });

        if (updateExtra) {
            setExtraMeetingUrl(
                window.location.origin +
                    `/event/${eventName}` +
                    `?name=${meeting_customer.meeting_name}&password=${newValue}`
            );
        }
    };

    const handleURLChange = e => {
        const newValue = e.target.value;
        setExtraUrl(newValue);
    };
    const handleMeetingURLChange = e => {
        const newValue = e.target.value;
        setExtraMeetingUrl(newValue);
    };

    const handleClear = e => {
        resetForm();
    };
    const handleMeetingClear = e => {
        resetMeetingForm();
    };

    const resetForm = () => {
        setCustomer({ name: "", password: "" });
        setExtraUrl("");
    };
    const resetMeetingForm = () => {
        setMeeting_Customer({ meeting_name: "", meeting_password: "" });
        setExtraMeetingUrl("");
    };

    const handleGeneratePassword = () => {
        const name = generateString(5, "u");
        const password = generateString(10);
        const mType = 1;
        handleCustomerNameChange(name, false);
        handleCustomerPasswordChange(password, false);
        setExtraUrl(
            window.location.origin + `/event/${eventName}` + `?name=${name}&password=${password}`
        );
        console.log(customer, name, password);
        addLiteParticipant(eventId, { name, password, mType });
    };
    const handleGenerateMeetingLink = () => {
        const name = generateString(5, "u");
        const password = generateString(10);
        const mType = 2;
        handleCustomerMeetingNameChange(name, false);
        handleCustomerMeetingPasswordChange(password, false);
        setExtraMeetingUrl(
            window.location.origin + `/event/${eventName}` + `?name=${name}&password=${password}`
        );
        console.log(meeting_customer, name, password);
        addLiteParticipant(eventId, { name, password, mType });
    };

    function handleCopy(event_name, name, password) {
        navigator.clipboard.writeText(
            window.location.origin + `/event/${event_name}` + `?name=${name}&password=${password}`
        );
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }
    // const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 1) {
            getLiteParticipantsList(eventId, 2);
            checkMeeting(true);
        } else {
            getLiteParticipantsList(eventId, 1);
            checkMeeting(false);
        }
    };
    const [isMeeting, checkMeeting] = React.useState({ isMeeting: false });
    return (
        <>
            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <div className={root}>
                    {eventType == 2 && (
                        <div>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab
                                    label={intl.formatMessage({
                                        id: "EVENT.PARTICIPANTSHAREDLG.TITLE"
                                    })}
                                />
                                <Tab label="Meeting Link" />
                            </Tabs>
                            {value === 0 && (
                                <div>
                                    <DialogContent>
                                        <DialogContentText>
                                            {intl.formatMessage({
                                                id: "EVENT.PARTICIPANTSHAREDLG.DESC"
                                            })}
                                        </DialogContentText>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Formik
                                                    initialValues={customer}
                                                    enableReinitialize
                                                    validate={values => {
                                                        const errors = {};

                                                        if (!values.name) {
                                                            errors.name = intl.formatMessage({
                                                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                            });
                                                        }

                                                        if (!values.password) {
                                                            errors.password = intl.formatMessage({
                                                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                            });
                                                        }

                                                        return errors;
                                                    }}
                                                    onSubmit={(
                                                        values,
                                                        { setStatus, setSubmitting }
                                                    ) => {
                                                        enableLoading();
                                                        setTimeout(() => {
                                                            if (customerId !== -1) {
                                                                dispatch(
                                                                    customersStore.actions.updateCustomers(
                                                                        Object.assign(
                                                                            customerValue,
                                                                            customer
                                                                        )
                                                                    )
                                                                );
                                                                disableLoading();
                                                                onClose();
                                                            } else {
                                                                dispatch(
                                                                    customersStore.actions.addCustomer(
                                                                        customer
                                                                    )
                                                                );
                                                                disableLoading();
                                                                onClose();
                                                            }
                                                        }, 1000);
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        status,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting
                                                    }) => (
                                                        <form
                                                            noValidate={true}
                                                            autoComplete="off"
                                                            className="kt-form"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            {status ? (
                                                                <div
                                                                    role="alert"
                                                                    className="alert alert-danger"
                                                                >
                                                                    <div className="alert-text">
                                                                        {status}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <div>
                                                                        <TextField
                                                                            type="text"
                                                                            margin="dense"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={intl.formatMessage(
                                                                                {
                                                                                    id:
                                                                                        "EVENT.PARTICIPANTSHAREDLG.FIELD.NAME"
                                                                                }
                                                                            )}
                                                                            className="kt-width-full"
                                                                            name="name"
                                                                            onBlur={handleBlur}
                                                                            onChange={e => {
                                                                                handleCustomerNameChange(
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={values.name}
                                                                            helperText={
                                                                                touched.name &&
                                                                                errors.name
                                                                            }
                                                                            error={Boolean(
                                                                                touched.name &&
                                                                                    errors.name
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <div>
                                                                        <TextField
                                                                            type="text"
                                                                            margin="dense"
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={intl.formatMessage(
                                                                                {
                                                                                    id:
                                                                                        "EVENT.PARTICIPANTSHAREDLG.FIELD.PASSOWRD"
                                                                                }
                                                                            )}
                                                                            className="kt-width-full"
                                                                            name="password"
                                                                            onBlur={handleBlur}
                                                                            onChange={e => {
                                                                                handleCustomerPasswordChange(
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={values.password}
                                                                            helperText={
                                                                                touched.password &&
                                                                                errors.password
                                                                            }
                                                                            error={Boolean(
                                                                                touched.password &&
                                                                                    errors.password
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            <div className="d-flex align-items-center">
                                                                <TextField
                                                                    type="text"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    multiline
                                                                    label={intl.formatMessage({
                                                                        id: "EVENT.FIELD.URL"
                                                                    })}
                                                                    variant="outlined"
                                                                    className="kt-width-full"
                                                                    name="direct_url"
                                                                    // onBlur={handleBlur}
                                                                    onChange={handleURLChange}
                                                                    value={extra_url}
                                                                    placeholder={
                                                                        window.location.origin +
                                                                        `/event/${eventName}?`
                                                                    }
                                                                    // helperText={errors.direct_url}
                                                                    // error={Boolean(
                                                                    //     errors.direct_url
                                                                    // )}
                                                                />
                                                                <div
                                                                    className={`col-md-2 
                                                                    ${classes.buttonContainer}`}
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        disabled={false}
                                                                        className={classes.button}
                                                                        onClick={handleCopyUrl}
                                                                    >
                                                                        {isCopied
                                                                            ? intl.formatMessage({
                                                                                  id:
                                                                                      "EVENT.PARTICIPANTSHAREDLG.COPIED"
                                                                              })
                                                                            : intl.formatMessage({
                                                                                  id:
                                                                                      "EVENT.PARTICIPANTSHAREDLG.COPY"
                                                                              })}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="kt-login__actions">
                                                                <DialogActions>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={handleClear}
                                                                    >
                                                                        {intl.formatMessage({
                                                                            id:
                                                                                "EVENT.PARTICIPANTSHAREDLG.RESET"
                                                                        })}
                                                                    </Button>
                                                                    <LoadingButton
                                                                        color="primary"
                                                                        disabled={isSubmitting}
                                                                        text={intl.formatMessage({
                                                                            id:
                                                                                "EVENT.PARTICIPANTSHAREDLG.GENERATE"
                                                                        })}
                                                                        loading={loading}
                                                                        onClick={
                                                                            handleGeneratePassword
                                                                        }
                                                                    />
                                                                </DialogActions>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>

                                            <EnhancedTableToolbar numSelected={selected.length} />
                                            <div className={classes.tableWrapper}>
                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size="medium"
                                                >
                                                    <EnhancedTableHead
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={
                                                            liteParticipantsList
                                                                ? liteParticipantsList.length
                                                                : 0
                                                        }
                                                        headRows={headRows}
                                                    />
                                                    <TableBody>
                                                        {stableSort(
                                                            liteParticipantsList,
                                                            getSorting(order, orderBy)
                                                        )
                                                            .slice(
                                                                page * rowsPerPage,
                                                                page * rowsPerPage + rowsPerPage
                                                            )
                                                            .map((row, index) => {
                                                                const isItemSelected = isSelected(
                                                                    row.id
                                                                );
                                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        aria-checked={
                                                                            isItemSelected
                                                                        }
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                        selected={isItemSelected}
                                                                    >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={
                                                                                    isItemSelected
                                                                                }
                                                                                onClick={event =>
                                                                                    handleCheckClick(
                                                                                        event,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                                inputProps={{
                                                                                    "aria-labelledby": labelId
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.password}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.expire_datetime ? (
                                                                                ""
                                                                            ) : (
                                                                                <Chip
                                                                                    size="small"
                                                                                    label="Active"
                                                                                    // onDelete={handleDelete}
                                                                                    className={
                                                                                        classes.chip
                                                                                    }
                                                                                    color="secondary"
                                                                                />
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                aria-label="Delete"
                                                                                onClick={event =>
                                                                                    handleDeleteClick(
                                                                                        event,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                aria-label="Delete"
                                                                                onClick={event =>
                                                                                    handleCopy(
                                                                                        row.event_name,
                                                                                        row.name,
                                                                                        row.password
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FileCopyIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        {emptyRows > 0 && (
                                                            <TableRow
                                                                style={{ height: 49 * emptyRows }}
                                                            >
                                                                <TableCell colSpan={8} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={liteParticipantsList.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                backIconButtonProps={{
                                                    "aria-label": "Previous Page"
                                                }}
                                                nextIconButtonProps={{
                                                    "aria-label": "Next Page"
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    </DialogContent>
                                </div>
                            )}
                            {value === 1 && (
                                <div>
                                    <DialogContent>
                                        <DialogContentText>
                                            {intl.formatMessage({
                                                id: "EVENT.GENERATINGMEETINGLINK.DESC"
                                            })}
                                        </DialogContentText>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Formik
                                                    initialValues={meeting_customer}
                                                    enableReinitialize
                                                    validate={values => {
                                                        const errors = {};

                                                        if (!values.meetimg_name) {
                                                            errors.meetimg_name = intl.formatMessage(
                                                                {
                                                                    id:
                                                                        "AUTH.VALIDATION.REQUIRED_FIELD"
                                                                }
                                                            );
                                                        }

                                                        if (!values.meetimg_password) {
                                                            errors.meetimg_password = intl.formatMessage(
                                                                {
                                                                    id:
                                                                        "AUTH.VALIDATION.REQUIRED_FIELD"
                                                                }
                                                            );
                                                        }

                                                        return errors;
                                                    }}
                                                    onSubmit={(
                                                        values,
                                                        { setStatus, setSubmitting }
                                                    ) => {
                                                        enableLoading();
                                                        setTimeout(() => {
                                                            if (customerId !== -1) {
                                                                dispatch(
                                                                    customersStore.actions.updateCustomers(
                                                                        Object.assign(
                                                                            customerValue,
                                                                            meeting_customer
                                                                        )
                                                                    )
                                                                );
                                                                disableLoading();
                                                                onClose();
                                                            } else {
                                                                dispatch(
                                                                    customersStore.actions.addCustomer(
                                                                        meeting_customer
                                                                    )
                                                                );
                                                                disableLoading();
                                                                onClose();
                                                            }
                                                        }, 1000);
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        status,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting
                                                    }) => (
                                                        <form
                                                            noValidate={true}
                                                            autoComplete="off"
                                                            className="kt-form"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            {status ? (
                                                                <div
                                                                    role="alert"
                                                                    className="alert alert-danger"
                                                                >
                                                                    <div className="alert-text">
                                                                        {status}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <div>
                                                                        <TextField
                                                                            type="text"
                                                                            margin="dense"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={intl.formatMessage(
                                                                                {
                                                                                    id:
                                                                                        "EVENT.PARTICIPANTSHAREDLG.FIELD.NAME"
                                                                                }
                                                                            )}
                                                                            className="kt-width-full"
                                                                            name="name"
                                                                            onBlur={handleBlur}
                                                                            onChange={e => {
                                                                                handleCustomerMeetingNameChange(
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={
                                                                                values.meeting_name
                                                                            }
                                                                            helperText={
                                                                                touched.name &&
                                                                                errors.name
                                                                            }
                                                                            error={Boolean(
                                                                                touched.name &&
                                                                                    errors.name
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <div>
                                                                        <TextField
                                                                            type="text"
                                                                            margin="dense"
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={intl.formatMessage(
                                                                                {
                                                                                    id:
                                                                                        "EVENT.PARTICIPANTSHAREDLG.FIELD.PASSOWRD"
                                                                                }
                                                                            )}
                                                                            className="kt-width-full"
                                                                            name="password"
                                                                            onBlur={handleBlur}
                                                                            onChange={e => {
                                                                                handleCustomerMeetingPasswordChange(
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={
                                                                                values.meeting_password
                                                                            }
                                                                            helperText={
                                                                                touched.password &&
                                                                                errors.password
                                                                            }
                                                                            error={Boolean(
                                                                                touched.password &&
                                                                                    errors.password
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            <div className="d-flex align-items-center">
                                                                <TextField
                                                                    type="text"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    multiline
                                                                    label={intl.formatMessage({
                                                                        id: "EVENT.FIELD.URL"
                                                                    })}
                                                                    variant="outlined"
                                                                    className="kt-width-full"
                                                                    name="direct_url"
                                                                    // onBlur={handleBlur}
                                                                    onChange={
                                                                        handleMeetingURLChange
                                                                    }
                                                                    value={extra_meeting_url}
                                                                    placeholder={
                                                                        window.location.origin +
                                                                        `/event/${eventName}?`
                                                                    }
                                                                    // helperText={errors.direct_url}
                                                                    // error={Boolean(
                                                                    //     errors.direct_url
                                                                    // )}
                                                                />
                                                                <div
                                                                    className={`col-md-2 
                                                                    ${classes.buttonContainer}`}
                                                                >
                                                                    <Button
                                                                        variant="contained"
                                                                        disabled={false}
                                                                        className={classes.button}
                                                                        onClick={
                                                                            handleCopyMeetingUrl
                                                                        }
                                                                    >
                                                                        {isCopied
                                                                            ? intl.formatMessage({
                                                                                  id:
                                                                                      "EVENT.PARTICIPANTSHAREDLG.COPIED"
                                                                              })
                                                                            : intl.formatMessage({
                                                                                  id:
                                                                                      "EVENT.PARTICIPANTSHAREDLG.COPY"
                                                                              })}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <div className="kt-login__actions">
                                                                <DialogActions>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={handleMeetingClear}
                                                                    >
                                                                        {intl.formatMessage({
                                                                            id:
                                                                                "EVENT.PARTICIPANTSHAREDLG.RESET"
                                                                        })}
                                                                    </Button>
                                                                    <LoadingButton
                                                                        color="primary"
                                                                        disabled={isSubmitting}
                                                                        text={intl.formatMessage({
                                                                            id:
                                                                                "EVENT.PARTICIPANTSHAREDLG.GENERATE"
                                                                        })}
                                                                        loading={loading}
                                                                        onClick={
                                                                            handleGenerateMeetingLink
                                                                        }
                                                                    />
                                                                </DialogActions>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>

                                            <EnhancedTableToolbar numSelected={selected.length} />
                                            <div className={classes.tableWrapper}>
                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size="medium"
                                                >
                                                    <EnhancedTableHead
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={
                                                            liteParticipantsList
                                                                ? liteParticipantsList.length
                                                                : 0
                                                        }
                                                        headRows={headRows}
                                                    />
                                                    <TableBody>
                                                        {stableSort(
                                                            liteParticipantsList,
                                                            getSorting(order, orderBy)
                                                        )
                                                            .slice(
                                                                page * rowsPerPage,
                                                                page * rowsPerPage + rowsPerPage
                                                            )
                                                            .map((row, index) => {
                                                                const isItemSelected = isSelected(
                                                                    row.id
                                                                );
                                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        aria-checked={
                                                                            isItemSelected
                                                                        }
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                        selected={isItemSelected}
                                                                    >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={
                                                                                    isItemSelected
                                                                                }
                                                                                onClick={event =>
                                                                                    handleCheckClick(
                                                                                        event,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                                inputProps={{
                                                                                    "aria-labelledby": labelId
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.password}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.expire_datetime ? (
                                                                                ""
                                                                            ) : (
                                                                                <Chip
                                                                                    size="small"
                                                                                    label="Active"
                                                                                    // onDelete={handleDelete}
                                                                                    className={
                                                                                        classes.chip
                                                                                    }
                                                                                    color="secondary"
                                                                                />
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <IconButton
                                                                                aria-label="Delete"
                                                                                onClick={event =>
                                                                                    handleDeleteClick(
                                                                                        event,
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                aria-label="Delete"
                                                                                onClick={event =>
                                                                                    handleCopy(
                                                                                        row.event_name,
                                                                                        row.name,
                                                                                        row.password
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FileCopyIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        {emptyRows > 0 && (
                                                            <TableRow
                                                                style={{ height: 49 * emptyRows }}
                                                            >
                                                                <TableCell colSpan={8} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={liteParticipantsList.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                backIconButtonProps={{
                                                    "aria-label": "Previous Page"
                                                }}
                                                nextIconButtonProps={{
                                                    "aria-label": "Next Page"
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    </DialogContent>
                                </div>
                            )}
                        </div>
                    )}
                    {eventType != 2 && (
                        <div>
                            <DialogTitle id="form-event-dialog-title">
                                {intl.formatMessage({
                                    id: "EVENT.PARTICIPANTSHAREDLG.TITLE"
                                })}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {intl.formatMessage({
                                        id: "EVENT.PARTICIPANTSHAREDLG.DESC"
                                    })}
                                </DialogContentText>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Formik
                                            initialValues={customer}
                                            enableReinitialize
                                            validate={values => {
                                                const errors = {};

                                                if (!values.name) {
                                                    errors.name = intl.formatMessage({
                                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                    });
                                                }

                                                if (!values.password) {
                                                    errors.password = intl.formatMessage({
                                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                    });
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                                enableLoading();
                                                setTimeout(() => {
                                                    if (customerId !== -1) {
                                                        dispatch(
                                                            customersStore.actions.updateCustomers(
                                                                Object.assign(
                                                                    customerValue,
                                                                    customer
                                                                )
                                                            )
                                                        );
                                                        disableLoading();
                                                        onClose();
                                                    } else {
                                                        dispatch(
                                                            customersStore.actions.addCustomer(
                                                                customer
                                                            )
                                                        );
                                                        disableLoading();
                                                        onClose();
                                                    }
                                                }, 1000);
                                            }}
                                        >
                                            {({
                                                values,
                                                status,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting
                                            }) => (
                                                <form
                                                    noValidate={true}
                                                    autoComplete="off"
                                                    className="kt-form"
                                                    onSubmit={handleSubmit}
                                                >
                                                    {status ? (
                                                        <div
                                                            role="alert"
                                                            className="alert alert-danger"
                                                        >
                                                            <div className="alert-text">
                                                                {status}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6}>
                                                            <div>
                                                                <TextField
                                                                    type="text"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={intl.formatMessage({
                                                                        id:
                                                                            "EVENT.PARTICIPANTSHAREDLG.FIELD.NAME"
                                                                    })}
                                                                    className="kt-width-full"
                                                                    name="name"
                                                                    onBlur={handleBlur}
                                                                    onChange={e => {
                                                                        handleCustomerNameChange(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={values.name}
                                                                    helperText={
                                                                        touched.name && errors.name
                                                                    }
                                                                    error={Boolean(
                                                                        touched.name && errors.name
                                                                    )}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <div>
                                                                <TextField
                                                                    type="text"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label={intl.formatMessage({
                                                                        id:
                                                                            "EVENT.PARTICIPANTSHAREDLG.FIELD.PASSOWRD"
                                                                    })}
                                                                    className="kt-width-full"
                                                                    name="password"
                                                                    onBlur={handleBlur}
                                                                    onChange={e => {
                                                                        handleCustomerPasswordChange(
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    value={values.password}
                                                                    helperText={
                                                                        touched.password &&
                                                                        errors.password
                                                                    }
                                                                    error={Boolean(
                                                                        touched.password &&
                                                                            errors.password
                                                                    )}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <div className="d-flex align-items-center">
                                                        <TextField
                                                            type="text"
                                                            margin="dense"
                                                            fullWidth
                                                            multiline
                                                            label={intl.formatMessage({
                                                                id: "EVENT.FIELD.URL"
                                                            })}
                                                            variant="outlined"
                                                            className="kt-width-full"
                                                            name="direct_url"
                                                            // onBlur={handleBlur}
                                                            onChange={handleURLChange}
                                                            value={extra_url}
                                                            placeholder={
                                                                window.location.origin +
                                                                `/event/${eventName}?`
                                                            }
                                                            // helperText={errors.direct_url}
                                                            // error={Boolean(
                                                            //     errors.direct_url
                                                            // )}
                                                        />
                                                        <div
                                                            className={`col-md-2 
                                                            ${classes.buttonContainer}`}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                disabled={false}
                                                                className={classes.button}
                                                                onClick={handleCopyUrl}
                                                            >
                                                                {isCopied
                                                                    ? intl.formatMessage({
                                                                          id:
                                                                              "EVENT.PARTICIPANTSHAREDLG.COPIED"
                                                                      })
                                                                    : intl.formatMessage({
                                                                          id:
                                                                              "EVENT.PARTICIPANTSHAREDLG.COPY"
                                                                      })}
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="kt-login__actions">
                                                        <DialogActions>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={handleClear}
                                                            >
                                                                {intl.formatMessage({
                                                                    id:
                                                                        "EVENT.PARTICIPANTSHAREDLG.RESET"
                                                                })}
                                                            </Button>
                                                            <LoadingButton
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                text={intl.formatMessage({
                                                                    id:
                                                                        "EVENT.PARTICIPANTSHAREDLG.GENERATE"
                                                                })}
                                                                loading={loading}
                                                                onClick={handleGeneratePassword}
                                                            />
                                                        </DialogActions>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>

                                    <EnhancedTableToolbar numSelected={selected.length} />
                                    <div className={classes.tableWrapper}>
                                        <Table
                                            className={classes.table}
                                            aria-labelledby="tableTitle"
                                            size="medium"
                                        >
                                            <EnhancedTableHead
                                                numSelected={selected.length}
                                                order={order}
                                                orderBy={orderBy}
                                                onSelectAllClick={handleSelectAllClick}
                                                onRequestSort={handleRequestSort}
                                                rowCount={
                                                    liteParticipantsList
                                                        ? liteParticipantsList.length
                                                        : 0
                                                }
                                                headRows={headRows}
                                            />
                                            <TableBody>
                                                {stableSort(
                                                    liteParticipantsList,
                                                    getSorting(order, orderBy)
                                                )
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row, index) => {
                                                        const isItemSelected = isSelected(row.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        checked={isItemSelected}
                                                                        onClick={event =>
                                                                            handleCheckClick(
                                                                                event,
                                                                                row.id
                                                                            )
                                                                        }
                                                                        inputProps={{
                                                                            "aria-labelledby": labelId
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>{row.name}</TableCell>
                                                                <TableCell>
                                                                    {row.password}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.expire_datetime ? (
                                                                        ""
                                                                    ) : (
                                                                        <Chip
                                                                            size="small"
                                                                            label="Active"
                                                                            // onDelete={handleDelete}
                                                                            className={classes.chip}
                                                                            color="secondary"
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        aria-label="Delete"
                                                                        onClick={event =>
                                                                            handleDeleteClick(
                                                                                event,
                                                                                row.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="Delete"
                                                                        onClick={event =>
                                                                            handleCopy(
                                                                                row.event_name,
                                                                                row.name,
                                                                                row.password
                                                                            )
                                                                        }
                                                                    >
                                                                        <FileCopyIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 49 * emptyRows }}>
                                                        <TableCell colSpan={8} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={liteParticipantsList.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page"
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </DialogContent>
                        </div>
                    )}
                </div>

                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        {intl.formatMessage({
                            id: "EVENT.PARTICIPANTSHAREDLG.CLOSE"
                        })}
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <Dialog
                    open={openDelDlg}
                    onClose={handleCloseDel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Will you surely remove this participant?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="primary" autoFocus>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

EventShareDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    eventId: PropTypes.any,
    eventName: PropTypes.any,
    eventType: PropTypes.any
};

const mapStateToProps = state => {
    return {
        liteParticipantsList: state.events.liteParticipantsList
    };
};

const mapDispatchToProps = dispatch => ({
    getLiteParticipantsList: (eventId, mType) =>
        dispatch(eventsStore.actions.getLiteParticipantsList(eventId, mType)),
    removeLiteParticipant: participantId =>
        dispatch(eventsStore.actions.removeLiteParticipant(participantId)),
    addLiteParticipant: (eventId, customer) =>
        dispatch(eventsStore.actions.addLiteParticipant(eventId, customer))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EventShareDlg));
